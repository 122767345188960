import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgbCollapseModule, NgbDatepickerModule, NgbTimepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';

import { SlimscrollDirective } from './slimscroll.directive';

import { PreloaderComponent } from './preloader/preloader.component';
import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { PortletComponent } from './portlet/portlet.component';
import { RouterModule } from '@angular/router';
import { GetExactTimePipe } from 'src/app/core/pipes/get-exact-time.pipe';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [SlimscrollDirective, PreloaderComponent, GetExactTimePipe, PagetitleComponent, PortletComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ClickOutsideModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
  ],
  // tslint:disable-next-line: max-line-length
  exports: [GetExactTimePipe, SlimscrollDirective, PreloaderComponent, PagetitleComponent, PortletComponent],
})
export class UIModule {}
