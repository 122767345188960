import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getExactTime',
})
export class GetExactTimePipe implements PipeTransform {
  transform(inputDateArg: string): string {
    const inputDate = new Date(inputDateArg);
    if (inputDate) {
      const hours = inputDate.getUTCHours();
      const minutes = inputDate.getUTCMinutes();
      const period = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM

      return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
    }

    return 'Nil';
  }
}
