import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, retry } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiResponse } from "../models/api-response.models";
import { CommonService } from "./common.service";

@Injectable({
  providedIn: "root",
})
export class Logoutservice {
  private apiURL: string = environment.API_ENDPOINT;

  constructor(private http: HttpClient, private commonService: CommonService) {}

  httpOptions = {
    headers: new HttpHeaders({
      Authorization: "bearer " + localStorage.getItem("token"),
    }),
  };

  logoutAdmin(obj: any) {
    return this.http
      .post<ApiResponse>(
        this.apiURL + "/v2/api/admin/admin_logout",
        obj,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.commonService.handleError));
  }
}
