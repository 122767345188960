import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api-response.models';
import { Router } from '@angular/router';
import { CommonService } from './common.service';
import { Logoutservice } from '../../core/services/logout.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private apiURL: string = environment.API_ENDPOINT;
  httpOptions = {
    headers: new HttpHeaders({}),
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private commonService: CommonService,
    private Logoutservice: Logoutservice
  ) {}

  login(obj: any) {
    return this.http
      .post<ApiResponse>(this.apiURL + '/v2/api/admin/admin_login', obj)
      .pipe(retry(1), catchError(this.commonService.handleError));
  }

  logout() {
    let obj = {
      strtoken: localStorage.getItem('token'),
      strLoginUserId: localStorage.getItem('userId'),
    };

    this.Logoutservice.logoutAdmin(obj).subscribe((res) => {
      if (res.success) {
        localStorage.clear();
        this.router.navigate(['/account/login']);
      } else {
        localStorage.clear();
        this.router.navigate(['/account/login']);
      }
    });
  }

  isUserHubAdmin() {
    const role = localStorage.getItem('role');
    return role === 'HUB-ADMIN';
  }

  hubAdminShop() {
    const shop = localStorage.getItem('quickType_arr_shops');
    return shop || '';
  }

  isUserQuickAdmin() {
    const role = localStorage.getItem('role');
    return role === 'QUICK-ADMIN';
  }
}
