import { Location } from '@angular/common';
import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnChanges, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import MetisMenu from 'metismenujs/dist/metismenujs';
import { Subscription } from 'rxjs';
import { ServiceCommenService } from 'src/app/core/services/service-common.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('sideMenu', { static: false }) sideMenu: ElementRef;
  @Input() isCondensed = false;

  menu: any;
  navItems = [];
  OriginalNavItems = [];

  isService = false;
  serviceName: string = '';
  arrayServiceNavItems = [];
  SubscribtionServiceNavItems: Subscription;

  constructor(private _serviceCommenService: ServiceCommenService, private location: Location, private router: Router) {}

  ngOnInit() {
    this.initNavItems();

    // Service Subscription
    this.SubscribtionServiceNavItems = this._serviceCommenService.$ServiceChangeDataTransfer.subscribe((responce) => {
      this.isService = false;
      this.arrayServiceNavItems = [];
      this.serviceName = '';
      this.navItems = this.OriginalNavItems;

      const { data, isInitialize } = responce;

      if (data === 'GAS' || data === 'WATER' || data === 'LAUNDARY' || data === 'CLEANING') {
        this.isService = true;
        this.serviceName = data;
        this.navItems = [];
      }

      if (data === 'GAS') {
        if (!isInitialize) this.router.navigate(['/services/gas/reports/order-report']);

        this.arrayServiceNavItems = [
          {
            icon: 'file-text',
            sub: [{ path: '/services/gas/reports/order-report', title: 'Order Report' }],
            title: 'Report',
          },
          {
            icon: 'home',
            sub: [
              { path: '/services/gas/shops/create-shop', title: 'Create Shop' },
              { path: '/services/gas/shops/list-shop', title: 'List Shop' },
              { path: '/services/gas/shops/update-shop', title: 'Update Shop' },
              { path: '/services/gas/shops/delete-shop', title: 'Delete Shop' },
            ],
            title: 'Shop',
          },
          {
            icon: 'award',
            sub: [
              { path: '/services/gas/products/create-product', title: 'Create Product' },
              { path: '/services/gas/products/list-product', title: 'List Product' },
              { path: '/services/gas/products/update-product', title: 'Update Product' },
              { path: '/services/gas/products/update-single-product', title: 'Update Single Product' },
              { path: '/services/gas/products/update-stock-product', title: 'Update Stock' },
              { path: '/services/gas/products/update-price-product', title: 'Update Price' },
              { path: '/services/gas/products/delete-product', title: 'Delete Product' },
            ],
            title: 'Product',
          },
        ];
      } else if (data === 'WATER') {
        if (!isInitialize) this.router.navigate(['/services/water/reports/order-report']);

        this.arrayServiceNavItems = [
          {
            icon: 'file-text',
            sub: [{ path: '/services/water/reports/order-report', title: 'Order Report' }],
            title: 'Report',
          },
          {
            icon: 'home',
            sub: [
              { path: '/services/water/shops/create-shop', title: 'Create Shop' },
              { path: '/services/water/shops/list-shop', title: 'List Shop' },
              { path: '/services/water/shops/update-shop', title: 'Update Shop' },
              { path: '/services/water/shops/delete-shop', title: 'Delete Shop' },
            ],
            title: 'Shop',
          },
          {
            icon: 'award',
            sub: [
              { path: '/services/water/products/create-product', title: 'Create Product' },
              { path: '/services/water/products/list-product', title: 'List Product' },
              { path: '/services/water/products/update-product', title: 'Update Product' },
              { path: '/services/water/products/update-single-product', title: 'Update Single Product' },
              { path: '/services/water/products/update-stock-product', title: 'Update Stock' },
              { path: '/services/water/products/update-price-product', title: 'Update Price' },
              { path: '/services/water/products/delete-product', title: 'Delete Product' },
            ],
            title: 'Product',
          },
        ];
      } else if (data === 'LAUNDARY') {
        if (!isInitialize) this.router.navigate(['/services/laundary/reports/order-report']);

        this.arrayServiceNavItems = [
          {
            icon: 'file-text',
            sub: [{ path: '/services/laundary/reports/order-report', title: 'Order Report' }],
            title: 'Report',
          },
          {
            icon: 'home',
            sub: [
              { path: '/services/laundary/shops/create-shop', title: 'Create Shop' },
              { path: '/services/laundary/shops/list-shop', title: 'List Shop' },
              { path: '/services/laundary/shops/update-shop', title: 'Update Shop' },
              { path: '/services/laundary/shops/delete-shop', title: 'Delete Shop' },
            ],
            title: 'Shop',
          },
          {
            icon: 'grid',
            sub: [
              { path: '/services/laundary/category/create-category', title: 'Create Category' },
              { path: '/services/laundary/category/list-category', title: 'List Category' },
              { path: '/services/laundary/category/update-category', title: 'Update Category' },
              { path: '/services/laundary/category/delete-category', title: 'Delete Category' },
            ],
            title: 'Category',
          },
          {
            icon: 'award',
            sub: [
              { path: '/services/laundary/products/create-product', title: 'Create Product' },
              { path: '/services/laundary/products/list-product', title: 'List Product' },
              { path: '/services/laundary/products/update-product', title: 'Update Product' },
              { path: '/services/laundary/products/update-single-product', title: 'Update Single Product' },
              { path: '/services/laundary/products/update-stock-product', title: 'Update Stock' },
              { path: '/services/laundary/products/update-price-product', title: 'Update Price' },
              { path: '/services/laundary/products/delete-product', title: 'Delete Product' },
            ],
            title: 'Product',
          },
          {
            icon: 'gift',
            sub: [
              { path: '/services/laundary/timeslot/create-timeslot-group', title: 'Create Timeslot Group' },
              { path: '/services/laundary/timeslot/list-timeslot-group', title: 'List Timeslot Group' },
              { path: '/services/laundary/timeslot/delete-timeslot-group', title: 'Delete Timeslot Group' },
              { path: '/services/laundary/timeslot/create-timeslot', title: 'Create Timeslot' },
              { path: '/services/laundary/timeslot/list-timeslot', title: 'List Timeslot' },
              { path: '/services/laundary/timeslot/update-timeslot', title: 'Update Timeslot' },
              { path: '/services/laundary/timeslot/delete-timeslot', title: 'Delete Timeslot' },
            ],
            title: 'Timeslot',
          },
        ];
      } else if (data === 'CLEANING') {
        if (!isInitialize) this.router.navigate(['/services/cleaning/reports/order-report']);

        this.arrayServiceNavItems = [
          {
            icon: 'file-text',
            sub: [{ path: '/services/cleaning/reports/order-report', title: 'Order Report' }],
            title: 'Report',
          },
          {
            icon: 'home',
            sub: [
              { path: '/services/cleaning/shops/create-shop', title: 'Create Shop' },
              { path: '/services/cleaning/shops/list-shop', title: 'List Shop' },
              { path: '/services/cleaning/shops/update-shop', title: 'Update Shop' },
              { path: '/services/cleaning/shops/delete-shop', title: 'Delete Shop' },
            ],
            title: 'Shop',
          },
          {
            icon: 'grid',
            sub: [
              { path: '/services/cleaning/frequency/create-frequency', title: 'Create Frequency' },
              { path: '/services/cleaning/frequency/list-frequency', title: 'List Frequency' },
              { path: '/services/cleaning/frequency/update-frequency', title: 'Update Frequency' },
              { path: '/services/cleaning/frequency/delete-frequency', title: 'Delete Frequency' },
            ],
            title: 'Frequency',
          },
          {
            icon: 'award',
            sub: [
              { path: '/services/cleaning/products/create-product', title: 'Create Product' },
              { path: '/services/cleaning/products/list-product', title: 'List Product' },
              { path: '/services/cleaning/products/update-product', title: 'Update Product' },
              { path: '/services/cleaning/products/update-single-product', title: 'Update Single Product' },
              { path: '/services/cleaning/products/update-stock-product', title: 'Update Stock' },
              { path: '/services/cleaning/products/update-price-product', title: 'Update Price' },
              { path: '/services/cleaning/products/delete-product', title: 'Delete Product' },
            ],
            title: 'Product',
          },
          {
            icon: 'gift',
            sub: [
              { path: '/services/cleaning/timeslot/create-timeslot-group', title: 'Create Timeslot Group' },
              { path: '/services/cleaning/timeslot/list-timeslot-group', title: 'List Timeslot Group' },
              { path: '/services/cleaning/timeslot/delete-timeslot-group', title: 'Delete Timeslot Group' },
              { path: '/services/cleaning/timeslot/create-timeslot', title: 'Create Timeslot' },
              { path: '/services/cleaning/timeslot/list-timeslot', title: 'List Timeslot' },
              { path: '/services/cleaning/timeslot/update-timeslot', title: 'Update Timeslot' },
              { path: '/services/cleaning/timeslot/delete-timeslot', title: 'Delete Timeslot' },
            ],
            title: 'Timeslot',
          },
        ];
      } else if (data === 'CLEAR' && !isInitialize) {
        this.router.navigate(['/dashboard']);
      }

      if (this.sideMenu) {
        this._activateMenuDropdown();

        // setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
        // }, 0);

        setTimeout(() => {
          this.menu = new MetisMenu(this.sideMenu.nativeElement);
        }, 600);
      }
    });

    // Take the Current URL
    const currentUrl = this.location.path();
    const splitCurrentUrl = currentUrl.split('/');

    if (currentUrl && splitCurrentUrl && splitCurrentUrl[1] === 'services') {
      let type = '';

      if (splitCurrentUrl[2] === 'gas') type = 'GAS';
      else if (splitCurrentUrl[2] === 'water') type = 'WATER';
      else if (splitCurrentUrl[2] === 'laundary') type = 'LAUNDARY';
      else if (splitCurrentUrl[2] === 'cleaning') type = 'CLEANING';

      this._serviceCommenService.$ServiceChangeDataTransfer.emit({ data: type, isInitialize: true });
    }
  }

  ngOnDestroy(): void {
    this.SubscribtionServiceNavItems.unsubscribe();
  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);

    this._activateMenuDropdown();
  }

  ngOnChanges() {
    if ((!this.isCondensed && this.sideMenu) || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }

  /**
   * Activates the menu dropdown
   */
  _activateMenuDropdown() {
    const links = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (window.location.pathname === links[i]['pathname']) {
        menuItemEl = links[i];
        break;
      }
    }

    if (menuItemEl) {
      menuItemEl.classList.add('active');

      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add('active');

        const parent2El = parentEl.parentElement;
        if (parent2El) {
          parent2El.classList.add('in');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.add('active');
          parent3El.firstChild.classList.add('active');
        }
      }
    }
  }

  initNavItems() {
    this.OriginalNavItems = JSON.parse(localStorage.getItem('modules'));
    this.navItems = this.OriginalNavItems;
  }
}
