import { Component, OnInit, Output, EventEmitter, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { AuthenticationService } from '../../core/services/auth.service';
import { ServiceCommenService } from '../../core/services/service-common.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit, AfterViewInit {
  openMobileMenu: boolean;
  adminRole = '';
  adminImage = '';
  isUserHubAdmin: boolean;
  isGAS = false;
  isWATER = false;
  isLAUNDARY = false;
  isCLEANING = false;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  constructor(
    private authenticationService: AuthenticationService,
    private _serviceCommenService: ServiceCommenService,
    private location: Location
  ) {}

  ngOnInit() {
    this.openMobileMenu = false;
    this.adminRole = localStorage.getItem('role');
    if (localStorage.getItem('shopDetails')) {
      const shopDetails = JSON.parse(localStorage.getItem('shopDetails')).shopDetails;
      this.adminImage = shopDetails.strImageUrl;
    } else {
      this.adminImage = localStorage.getItem('role');
    }

    this.userRoleCheck();

    const role = localStorage.getItem('role');

    if (role === 'ADMIN') {
      this.isCLEANING = true;
      this.isGAS = true;
      this.isLAUNDARY = true;
      this.isWATER = true;
    } else if (role === 'SERVICE-ADMIN') {
      const serviceType = localStorage.getItem('serviceType');

      if (serviceType === 'CLEANING') {
        this.isCLEANING = true;
      } else if (serviceType === 'LAUNDARY') {
        this.isLAUNDARY = true;
      } else if (serviceType === 'WATER') {
        this.isWATER = true;
      } else if (serviceType === 'GAS') {
        this.isGAS = true;
      }
    }
  }

  ngAfterViewInit(): void {
    // Take the Current URL
    const currentUrl = this.location.path();
    const splitCurrentUrl = currentUrl.split('/');
    let type = 'CLEAR';

    if (currentUrl && splitCurrentUrl && splitCurrentUrl[1] === 'services') {
      if (splitCurrentUrl[2] === 'gas') type = 'GAS';
      else if (splitCurrentUrl[2] === 'water') type = 'WATER';
      else if (splitCurrentUrl[2] === 'laundary') type = 'LAUNDARY';
      else if (splitCurrentUrl[2] === 'cleaning') type = 'CLEANING';
    }

    const list = document.getElementById(type) as HTMLElement;
    if (list) list.classList.add('active');
  }

  userRoleCheck() {
    this.isUserHubAdmin = this.authenticationService.isUserHubAdmin();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authenticationService.logout();
  }

  profile() {}

  changePassword() {}

  changeServiceFromSlider(type: any) {
    const CLEAR = document.getElementById('CLEAR') as HTMLElement;
    const GAS = document.getElementById('GAS') as HTMLElement;
    const WATER = document.getElementById('WATER') as HTMLElement;
    const LAUNDARY = document.getElementById('LAUNDARY') as HTMLElement;
    const CLEANING = document.getElementById('CLEANING') as HTMLElement;

    if (CLEAR) CLEAR.classList.remove('active');
    if (GAS) GAS.classList.remove('active');
    if (WATER) WATER.classList.remove('active');
    if (LAUNDARY) LAUNDARY.classList.remove('active');
    if (CLEANING) CLEANING.classList.remove('active');

    const list = document.getElementById(type) as HTMLElement;
    if (list) list.classList.add('active');

    this._serviceCommenService.$ServiceChangeDataTransfer.emit({ data: type, isInitialize: false });
  }
}
